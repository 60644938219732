












































































































import Vue from "vue";
import PolizaService, {
  GetCobroPolizaDto,
  GetPolizaDto
} from "@/api/poliza/PolizaService";
import { GetPaginateDto } from "@/api/interfaces";
import PolizaCobroAdd from "@/components/admin/poliza/cobro/PolizaCobroAdd.vue";

export default Vue.extend({
  components: {
    PolizaCobroAdd
  },
  data() {
    return {
      fechaInicio: new Date().toISOString().substr(0, 10),
      fechaFin: new Date().toISOString().substr(0, 10),
      dato: {},
      dialog: false,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: "Secuencia", value: "secuencia" },
        { text: "Fecha Ingreso", value: "FechaIngreso" },
        { text: "Fecha Aplicación", value: "FechaAplicacion" },
        { text: "$ Valor Cobro", value: "ValorCobro" },
        { text: "Forma Cobro", value: "getFormaCobroDisplay" },
        { text: "Observación", value: "observacion" }

        //{ text: "Acción", value: "actions", sortable: false }
      ],
      cobros: {
        items: [],
        meta: {
          totalItems: 0,
          itemCount: 0,
          itemsPerPage: 10,
          totalPages: 0,
          currentPage: 1
        }
      } as GetPaginateDto<GetCobroPolizaDto>,
      filters: {
        secuencia: ""
      },
      idPoliza: this.$route.params.id,
      poliza: null as null | GetPolizaDto,
      remisoriasUnion: {
        sumatoria: {
          primaAnual: 0,
          derechoEmision: 0,
          derechoAgencia: 0,
          valorCobro: 0,
          saldo: 0
        }
      }
    };
  },
  metaInfo() {
    const poliza = this.poliza as any;
    return {
      title: `Póliza ${poliza && poliza.id ? "#" + poliza.numero : "No Existe"}`
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        observacion: "",
        formaCobro: "",
        valorCobro: 0
      };
      this.dato = newDto;
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    loadData() {
      this.fetchData();
      this.loadRemisoriasUnion();
    },
    fetchData(search = false) {
      const params = new URLSearchParams({
        page: search ? "1" : this.cobros.meta.currentPage.toString(),
        limit: "10",
        ...this.filters
        //fechaInicio: this.fechaInicio,
        //fechaFin: this.fechaFin
      }).toString();
      PolizaService.getCobros(this.idPoliza, params).then(response => {
        this.cobros = response;
      });
    },
    loadPoliza() {
      PolizaService.get(this.idPoliza)
        .then(response => {
          this.poliza = response;
        })
        .catch(() => {
          //
        });
    },
    loadRemisoriasUnion() {
      PolizaService.getRemisoriasUnion(this.idPoliza)
        .then(response => {
          this.remisoriasUnion = response;
        })
        .catch(() => {
          //
        });
    }

    //nuevo
  },
  created() {
    this.loadPoliza();
    this.loadRemisoriasUnion();
    this.fetchData();
  }
});
