<template>
  <v-dialog v-bind:value="dialog" persistent max-width="600px">
    <v-form>
      <v-card>
        <v-card-title>
          <span class="headline">{{
            dtoSave.id ? "Editar Cobro" : "Nuevo Cobro"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="# Secuencia"
                  v-model.number="dtoSave.secuencia"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <DateTimePicker
                  label="Fecha Ingreso"
                  v-model="dtoSave.fechaIngreso"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <DateTimePicker
                  label="Fecha Aplicación"
                  v-model="dtoSave.fechaAplicacion"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Forma Cobro"
                  :items="formaPagoOptions"
                  :item-text="'text'"
                  :item-value="'value'"
                  v-model="dtoSave.formaCobro"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Valor Cobro"
                  v-model.number="dtoSave.valorCobro"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Observaciones"
                  v-model="dtoSave.observacion"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>* campos requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DateTimePicker from "@/components/shared/DateTimePicker.vue";
import PolizaService from "@/api/poliza/PolizaService";
export default {
  components: {
    DateTimePicker
    //VSelectize
  },
  props: ["dialog", "dato"],
  data() {
    return {
      idPoliza: this.$route.params.id,
      dtoSave: {
        fechaIngreso: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        fechaAplicacion: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      //selects
      formaPagoOptions: [
        { text: "TC Internacional", value: "1" },
        { text: "Cheque Electrónico", value: "2" },
        { text: "Cheque Banco Intern", value: "3" },
        { text: "Cheque Local Intern", value: "4" },
        { text: "Transferencia", value: "5" },
        { text: "Valor de Rescate", value: "6" }
      ]
    };
  },
  methods: {
    dialogChange(value) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      const dto = { ...this.dtoSave };
      PolizaService.createCobro(this.idPoliza, dto)
        .then(() => {
          //
          this.$emit("saved", "someValue");
          this.close();
        })
        .catch(error => {
          //
          let mensaje = "Intente más tarde";
          if (error.response && error.response.data) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            icon: "error",
            title: "Cobro no se pudo guardar",
            text: mensaje
          });
          //
        });
    }
  },
  created() {
    this.dtoSave = {};
    this.dtoSave = { ...this.dato };
  }
};
</script>
